.radio-button {
    cursor: pointer;
    transform-origin: 50% 50%;
    transform-style: preserve-3d;
    transition: transform 0.14s ease;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}
.radio-button .label {
    margin-left: 8px;
}
.radio-button:active {
    transform: rotateX(30deg);
}
.radio-button input {
    display: none;
}
.radio-button input + div {
    border: 3px solid rgba(255, 130, 0, 0.2);
    border-radius: 50%;
    position: relative;
    width: 24px;
    height: 24px;
}
.radio-button input + div svg {
    fill: none;
    stroke-width: 3.6;
    stroke: #ff8200;
    stroke-linecap: round;
    stroke-linejoin: round;
    width: 24px;
    height: 24px;
    display: block;
    position: absolute;
    left: -3px;
    top: -3px;
    right: -3px;
    bottom: -3px;
    z-index: 1;
    stroke-dashoffset: 124.6;
    stroke-dasharray: 0 162.6 133 29.599999999999994;
    transition: all 0.4s ease 0s;
}
.radio-button input:checked + div svg {
    stroke-dashoffset: 162.6;
    stroke-dasharray: 0 162.6 28 134.6;
    transition: all 0.4s ease 0.2s;
}
.radio-button input:checked + div:before {
    opacity: 0;
    transform: scale(0.3) translate(-50%, -50%) rotate(45deg);
    animation: bounceInBeforeDont 0.3s linear forwards 0s;
}
.radio-button input:checked + div:after {
    opacity: 0;
    transform: scale(0.3) translate(-50%, -50%) rotate(-45deg);
    animation: bounceInAfterDont 0.3s linear forwards 0s;
}
@keyframes bounceInBefore {
    0% {
        opacity: 0;
        transform: scale(0.3) translate(-50%, -50%) rotate(45deg);
    }
    50% {
        opacity: 0.9;
        transform: scale(1.1) translate(-50%, -50%) rotate(45deg);
    }
    80% {
        opacity: 1;
        transform: scale(0.89) translate(-50%, -50%) rotate(45deg);
    }
    100% {
        opacity: 1;
        transform: scale(1) translate(-50%, -50%) rotate(45deg);
    }
}
@keyframes bounceInAfter {
    0% {
        opacity: 0;
        transform: scale(0.3) translate(-50%, -50%) rotate(-45deg);
    }
    50% {
        opacity: 0.9;
        transform: scale(1.1) translate(-50%, -50%) rotate(-45deg);
    }
    80% {
        opacity: 1;
        transform: scale(0.89) translate(-50%, -50%) rotate(-45deg);
    }
    100% {
        opacity: 1;
        transform: scale(1) translate(-50%, -50%) rotate(-45deg);
    }
}
@keyframes bounceInBeforeDont {
    0% {
        opacity: 1;
        transform: scale(1) translate(-50%, -50%) rotate(45deg);
    }
    100% {
        opacity: 0;
        transform: scale(0.3) translate(-50%, -50%) rotate(45deg);
    }
}
@keyframes bounceInAfterDont {
    0% {
        opacity: 1;
        transform: scale(1) translate(-50%, -50%) rotate(-45deg);
    }
    100% {
        opacity: 0;
        transform: scale(0.3) translate(-50%, -50%) rotate(-45deg);
    }
}